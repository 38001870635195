.App {
  height: 100vh;
  width: 100%;
}

.active .facing-group-item > rect,
.facing-group-item.active rect {
  fill: #ED2626
}

.active .facing-group-item image + rect,
.facing-group-item.active image + rect {
  fill: #ED2626;
  stroke: #ED2626;
  stroke-width: 1;
}

body.is-dragging-from-clipboard #drop-area {
  display: none;
}

body.is-dragging:not(.is-dragging-from-clipboard) #dnd-drop-layer-clipboard-2 {
  pointer-events: none;
}

body.is-dragging:not(.is-over) #custom-dragged-clipboard-item-1,
body.is-dragging.is-over #custom-dragged-board-item-1,
body.is-dragging:not(.is-over) #custom-dragged-clipboard-item-2,
body.is-dragging.is-over #custom-dragged-board-item-2 {
  display: none;
}

body.is-dragging.is-over #custom-dragged-clipboard-item-1,
body.is-dragging.is-over #custom-dragged-clipboard-item-2,
body.is-dragging:not(.is-over) #custom-dragged-board-item-2 {
  display: block;
}

body.is-dragging.drag-start #custom-dragged-board-item-2 {
  display: none;
}

body.is-dragging.drag-start #custom-dragged-clipboard-item-2 {
  display: block;
}

body.is-dragging-from-clipboard.is-dragging:not(.drag-start):not(.is-over) #clipboard-drawer {
  display: none;
}

body.is-dragging-fixture .selected-fixture {
  background-color: transparent!important;
}

body.is-dragging-fixture .selected-fixture .facing-group-item > rect {
  fill: transparent;
}

body.is-dragging-fixture .selected-fixture .facing-group-item line {
  stroke: none;
}

body.is-dragging-fixture .selected-fixture image  {
  display: none;
}

body.drag-copy div.draggable-item > svg rect.single-rect {
  fill: #17D1A4;
  stroke-dasharray: unset;
}

body.drag-copy div.is-group.draggable-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #00796B;
  background-color: #17D1A4;
  z-index: -1;
}

.display-only-active {
  background-color: #17D1A4!important;
}

body.is-indicator #sku-size-preview {
  display: none;
}

body.is-dragging:not(.workbench-opened, .is-dragging-fixture, .is-dragging-clipboard-fixture) #footer-info > * {
  display: none;
}

body.is-dragging:not(.workbench-opened, .is-dragging-fixture, .is-dragging-clipboard-fixture) #footer-info {
  justify-content: center!important;
}

body.is-dragging:not(.workbench-opened, .is-dragging-fixture, .is-dragging-clipboard-fixture) #footer-wrapper {
  max-width: unset!important;
  padding: 0
}

#workbench-drop-message {
  display: none;
}

body.is-dragging:not(.workbench-opened, .is-dragging-fixture, .is-dragging-clipboard-fixture) #workbench-drop-message {
  display: block;
}

#workbench-extended {
  display: none;
  height: 100%;
  position: relative;
}

body.is-dragging:not(.workbench-opened, .is-dragging-fixture, .is-dragging-clipboard-fixture) #workbench-extended {
  display: flex;
  width: 100%;
  height: 100%;
}

body.is-dragging:not(.workbench-opened) #info-box {
  height: 100%;
}

.workbench-phantom-facing {
  position: absolute;
  left: 0px;
  width: 1px;
  height: 1px;
}

svg[id^="splicer-"].active .hatch-path,
svg[id^="splicer-"].active .hatch-pattern {
  stroke: #CE0919;
}

svg[id^="splicer-"].active .splicer-background {
  fill: #CE0919;
}
